import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <>
    <div
      style={{
        backgroundColor: `#5a5a5a`,
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center'
      }}
    >
      <p style={{ margin: 0, textAlign: 'center', color: '#ffffff' }}>
        10023 CHIERI (TO), Piazza Umberto I, 1 - "Torre dei Merlenghi"
      </p>
    </div>
    <div
      style={{
        background: `#002D74`,
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center',
        color: '#ffffff'
      }}
    >
      <p style={{ margin: 0, textAlign: 'center' }}>
          STUDIO NOTARILE FEDERICO BASSO | 10023 CHIERI (TO), Piazza Umberto I, 1 - "Torre dei Merlenghi" | <span style={{ color: '#ffffff' }}>+ 39 011 0898786</span>  - fbasso@notariato.it
      </p>
    </div>
  </>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
