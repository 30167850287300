import PropTypes from "prop-types"
import React from "react"

import Image from "../components/image"

const SubHeader = () => (
    <div
      style={{
        margin: `0 auto`,
        maxWidth: '100%'
      }}
    >
        <Image img={'arco.jpg'} />
        <div
          style={{
            backgroundColor: `#002D74`,
            width: '100%'
          }}
        >
            <p
              style={{
                color: `#ffffff`,
                textAlign: 'center',
                paddingTop: 30,
                paddingBottom: 30
              }}
            >
                Contatta il +39 011 0898786 - fbasso@notariato.it
            </p>
        </div>
    </div>
)

SubHeader.propTypes = {
  siteTitle: PropTypes.string,
}

SubHeader.defaultProps = {
  siteTitle: ``,
}

export default SubHeader
