import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

function renderImage(file, cls, alt) {
  if (file !== undefined) {
    return (
      <Img fluid={file.node.childImageSharp.fluid} className={cls} alt={alt}/>
    )
  }
}

const Image = ({img, cls, alt}) => {
  return <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              extension
              relativePath
              childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    `}
    render={(data) => {
      const image = data.images.edges.find(
        image => image.node.relativePath === img
      )
      return(renderImage(image, cls, alt))
    }}
  />
}

export default Image
